import React, { useState } from 'react';
import './IntroductoryView.css';
import RoleSelection from '../../components/RoleSelection/RoleSelection';
import SkillSelection from '../../components/SkillSelection/SkillSelection';
import useRole from '../../hooks/useRole';
import useSkill from '../../hooks/useSkill';
import ResumeUpload from '../../components/ResumeUpload/ResumeUpload';
import { getTenant } from '../../hooks/helpers';
import { api, getUserId, IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Navigation = ({ imageSrc }) => (
  <div className="nav w_n">
    <div className="nav-cont welcome">
      <div className="nav-left">
        <img
          src={`${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/logo/`}
          loading="lazy"
          alt="logo"
          className="image welcome"
        />
      </div>
    </div>
  </div>
);

const WelcomeSection = () => (
  <div className="div-block-78 p1-block">
    <div className="div-block-79">
      <img
        src="/images/Untitled-1.jpg"
        loading="lazy"
        sizes="(max-width: 479px) 100vw, 382.7578125px"
        srcSet="/images/Untitled-1-p-500.jpeg 500w, /images/Untitled-1.jpg 639w"
        alt=""
        className="image-47"
      />
      <div className="div-block-80">
        <div className="text-block-60">
          Welcome to {process.env.REACT_APP_IBL_SPA_TITLE}
        </div>
        <div className="text-block-61">
          Let’s set your learning profile. It will be used to find instructional
          content personalized to your skills and goals. These selected
          resources, along with our AI-driven pedagogical method, will allow you
          to boost your career and set a successful life-long learning
          experience.
        </div>
      </div>
    </div>
  </div>
);

const Footer = ({
  steps,
  currentStep,
  nextStepLabel,
  prevStepLabel,
  onNext,
  onPrev,
  disableNext,
  roleMin = 3,
  stepName = '',
  selectedCount = 0,
  loading = false, // Add loading prop to manage spinner and button disabling
}) => (
  <div className="welcome_footer" style={{ zIndex: '1111' }}>
    <div className="dots">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`div-block-82 ${index === currentStep ? 'active' : ''}`}
        />
      ))}
    </div>
    <div
      className="div-block-81"
      style={{
        justifyContent: `${currentStep === 0 ? 'flex-end' : 'space-between'}`,
      }}
    >
      {prevStepLabel && (
        <div className="g_s_btn-before" onClick={onPrev}>
          <div>{prevStepLabel}</div>
        </div>
      )}

      {nextStepLabel && (
        <>
          <div className="div-block-240">
            {/*{currentStep !== 0 && currentStep !== 3 && (
              <div className="text-block-157">
                <span id="rs">{selectedCount}</span> {stepName}
              </div>
            )}*/}

            {currentStep === 3 && (
              <div
                className="g_s_btn-before"
                onClick={onNext}
                style={{ marginRight: '3%' }}
              >
                <div>Skip Step</div>
              </div>
            )}
            <div
              className={`g_s_btn ${!disableNext && !loading ? '' : 'disabled'}`}
              onClick={!disableNext && !loading ? onNext : () => {}}
            >
              <div>{loading ? <IBLSpinLoader size={20} /> : nextStepLabel}</div>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);

const FinalStep = ({ loading }) => (
  <div className="div-block-78">
    <div className="div-block-92">
      {loading && <IBLSpinLoader size={40} />}
      <div className="text-block-66">Start Boosting Your Skills!</div>
      <div className="text-block-67">
        Check our content selection or manually add more learning items
      </div>
      <img
        src="/images/4721728.jpg"
        loading="lazy"
        alt=""
        className="image-51"
      />
    </div>
  </div>
);

const IntroductoryView = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { searchedRoles, setSearchedRoles, rolesLoaded, setRolesLoaded, addNewRole } =
    useRole();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const { searchedSkills, setSearchedSkills, skillsLoaded, setSkillsLoaded, addNewSkills } =
    useSkill();
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const roleMin =
    parseInt(process.env.REACT_APP_IBL_APP_START_ROLE_MIN, 10) || 3;
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const formatSelfReportedSkillData = () => {
    const skillList = [];

    skillList.push(
      ...selectedSkills.map((skill) => ({
        name: skill._source.name,
        starCount: (skill.rating !== undefined && skill.rating !== null && skill.rating !== '' && !isNaN(skill.rating)) ? skill.rating : 0,
      }))
    );

    return {
      user_id: getUserId(),
      skills: skillList.map((skill) => skill.name),
      data: {
        level: skillList.map((skill) => skill.starCount),
      },
    };
  };

  const formatDesiredRoles = () => {
    const roleList = [];

    roleList.push(...selectedRoles.map((role) => role._source));

    return {
      user_id: getUserId(),
      roles: roleList.map((role) => role.name),
    };
  };

  const uploadSelectedSkills = () => {
    return new Promise((resolve) => {
      api.ibldmskills.addSelfReportedSkill(
        JSON.stringify(formatSelfReportedSkillData()),
        (data) => {
          console.log('Skills uploaded successfully:', data);
          resolve();
        },
        (error) => {
          console.log('Error uploading skills:', error);
          resolve(); // Resolve even on error
        }
      );
    });
  };

  const uploadSelectedRoles = () => {
    return new Promise((resolve) => {
      api.ibldmplatform.createOrUpdateDesiredRoles(
        JSON.stringify(formatDesiredRoles()),
        (data) => {
          console.log('Roles uploaded successfully:', data);
          resolve();
        },
        (error) => {
          console.log('Error uploading roles:', error);
          resolve(); // Resolve even on error
        }
      );
    });
  };

  const handleNext = async () => {
    if (currentStep === 4) {
      // Show loading spinner and disable the button
      setLoading(true);

      // Trigger the upload and wait for both promises to resolve
      await Promise.all([uploadSelectedSkills(), uploadSelectedRoles()]);

      // Redirect to the home page after completion
      window.location.href = '/';
      return;
    }
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const disableNextButton = () => {
    if (currentStep === 1 && selectedRoles.length < 1) return true;
    if (currentStep === 2 && selectedSkills.length < roleMin) return true;
    if (currentStep === 3 && !resumeUploaded) return true;
    return false;
  };

  return (
    <>
      <div className="welcome_div show">
        {currentStep === 0 && (
          <div className="p1">
            <Navigation imageSrc="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png" />
            <WelcomeSection />
            <Footer
              steps={[1, 2, 3, 4, 5]}
              currentStep={currentStep}
              nextStepLabel="Get Started"
              onNext={handleNext}
            />
          </div>
        )}
        {currentStep === 1 && (
          <div className="p2">
            <Navigation imageSrc="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png" />
            <RoleSelection
              selectedRoles={selectedRoles}
              setSelectedRoles={setSelectedRoles}
              searchedRoles={searchedRoles}
              rolesLoaded={rolesLoaded}
              setRolesLoaded={setRolesLoaded}
              setSearchedRoles={setSearchedRoles}
              addNewRole={addNewRole}
            />
            <Footer
              steps={[1, 2, 3, 4, 5]}
              currentStep={currentStep}
              prevStepLabel="Previous Step"
              nextStepLabel="Next Step"
              onNext={handleNext}
              onPrev={handlePrev}
              disableNext={disableNextButton()}
              roleMin={1}
              stepName="roles selected"
              selectedCount={
                selectedRoles.length <= 1 ? selectedRoles.length : 1
              } // Pass the current count of selected roles
            />
          </div>
        )}
        {currentStep === 2 && (
          <div className="p3">
            <Navigation imageSrc="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png" />
            <SkillSelection
              selectedSkills={selectedSkills}
              setSelectedSkills={setSelectedSkills}
              searchedSkills={searchedSkills}
              roleMin={roleMin}
              setSearchedSkills={setSearchedSkills}
              addNewSkills={addNewSkills}
            />
            <Footer
              steps={[1, 2, 3, 4, 5]}
              currentStep={currentStep}
              prevStepLabel="Previous Step"
              nextStepLabel="Next Step"
              onNext={handleNext}
              onPrev={handlePrev}
              disableNext={disableNextButton()}
              roleMin={roleMin}
              stepName="skills selected"
              selectedCount={
                selectedSkills.length <= roleMin
                  ? selectedSkills.length
                  : roleMin
              } // Pass the current count of selected skills
            />
          </div>
        )}
        {currentStep === 3 && (
          <div className="p4">
            <Navigation imageSrc="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png" />
            <ResumeUpload
              setResumeUploaded={setResumeUploaded}
              resumeUploaded={resumeUploaded}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />{' '}
            {/* Set resumeUploaded to true upon successful upload */}
            <Footer
              steps={[1, 2, 3, 4, 5]}
              currentStep={currentStep}
              prevStepLabel="Previous Step"
              nextStepLabel="Next Step"
              onNext={handleNext}
              onPrev={handlePrev}
              disableNext={disableNextButton()}
              roleMin={1}
              stepName="resume uploaded"
              selectedCount={resumeUploaded ? 1 : 0} // Indicate if the resume is uploaded
            />
          </div>
        )}
        {currentStep === 4 && (
          <div className="p5">
            <Navigation imageSrc="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png" />
            <FinalStep loading={loading} />
            <Footer
              steps={[1, 2, 3, 4, 5]}
              currentStep={currentStep}
              prevStepLabel="Previous Step"
              nextStepLabel="Go"
              onNext={handleNext}
              onPrev={handlePrev}
              roleMin={1}
              stepName="Application Complete"
              selectedCount={1}
              loading={loading} // Pass loading state to Footer
            />
          </div>
        )}
      </div>
    </>
  );
};

export default IntroductoryView;
