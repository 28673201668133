import React, { useState, useCallback, useRef } from 'react';
import SkillRating from '../SkillRating/SkillRating';
import { getSkills, slugify } from '../../hooks/helpers';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import { useDebounce } from '../../hooks/useDebounce';

const SkillSelection = ({
  selectedSkills,
  setSelectedSkills,
  searchedSkills,
  setSearchedSkills,
  roleMin,
  addNewSkills
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const skillCallBack = (data) => {
    if (data?.results) {
      setSearchedSkills(data);
      return;
    }
    setSearchedSkills({
      results: [],
      num_pages: 1,
    });
  };

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      setShowSkillNotFoundUI(searchValue.length > 1)
      getSkills(skillCallBack, 1, [], searchValue);
    }, 400),
    []
  );

  const handleInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    getSkills(skillCallBack, selected + 1, [], searchTerm);
  };

  const handleSkillToggle = (skill) => {
    setSelectedSkills((prevSelectedSkills) =>
      prevSelectedSkills.some((s) => s._source.id === skill._source.id)
        ? prevSelectedSkills.filter((s) => s._source.id !== skill._source.id)
        : [...prevSelectedSkills, skill]
    );
  };

  // Separate the selected and unselected skills
  const unselectedSearchedSkills = searchedSkills.results.filter(
    (skill) => !selectedSkills.some((s) => s._source.id === skill._source.id)
  );

  //ADD ROLES
  const notificationRef = useRef(null)
  const defaultFields = {
    title:"",
  }
  const [addSkillModalFocused, setAddSkillModalFocused] = useState(false);
  const [showSkillNotFoundUI, setShowSkillNotFoundUI] = useState(false)
  const [fields, setFields] = useState(defaultFields)

  const handleCloseAddSkill = () => {
    setSubmittingSkill(false)
    setFields(defaultFields)
    setNotification({})
    setAddSkillModalFocused(false)
  }

  const [notification, setNotification] = useState({
    success:null,
    msg:null
  })
  const handleSetNotification = (success, msg, callback=()=>{}) => {
    setNotification({
      msg: msg,
      success
    })
    setTimeout(()=>{
      callback()
    }, 2500)
  }

  useDebounce(()=>{
    if(notification?.msg){
      notificationRef.current.toast()
    }
  }, 200, [notification])

  const [submittingSkill, setSubmittingSkill] = useState(false)

  const handleAddSkillInputChange = (e) => {
    setFields({
      ...fields,
      [e?.target?.name]:e?.target?.value
    })
  }

  const handleAddSkillSubmission = () => {
    setNotification({})
    if(!fields.title){
      return
    }
    const skillSlug = `${slugify(fields.title)}-${Date.now()}`
    setSubmittingSkill(true)
    const skillData = {
      name:fields.title,
      slug:skillSlug,

    }
    addNewSkills(skillData, ()=>{
      //success
      const updatedSkillData = {
        _source:{
          name:fields.title,
          slug:skillSlug,
          id:skillSlug,
          /*data:{
            description: fields.description
          }*/
        }
      }
      setSelectedSkills((old)=>[
        updatedSkillData,
        ...old
      ])
      setSearchedSkills({
        ...searchedSkills,
        results:[
          updatedSkillData,
          ...searchedSkills?.results
        ]
      })
      handleSetNotification(true, "Skill created successfully.", ()=>{
        handleCloseAddSkill()
      })
      setSubmittingSkill(false)
    }, ()=>{
      //error
      handleSetNotification(false, "An error occurred. Try again!")
      setSubmittingSkill(false)
    })
  }

  return (
    <>
      <SlAlert ref={notificationRef} variant={notification?.success ? "success" : "danger"} duration={5000} closable>
        <SlIcon slot="icon" name={notification?.success ? "check2-circle" : "exclamation-octagon"} />
        <strong>{notification?.msg}</strong>
      </SlAlert>
      <div className="div-block-78 sp">
        <div className="div-block-84 sk">
          <div className="text-block-62 res">
            Add More Skills to Your Profile or Select New Ones
          </div>
          <div className="form-block welcome sk skills w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get"
                  className="form welcome_f v-flex"
                  data-wf-page-id="6596e21ac435cac3c66baaef" data-wf-element-id="70b076f3-9681-eba1-cb85-a403ca22711d"
                  onSubmit={(e) => e.preventDefault()}
            >
              <div className="w-layout-hflex flex-block-63">
                <div className="div-block"><img src="/images/magnifying-glass_1magnifying-glass.png" loading="lazy"
                                                alt=""
                                                className="image-2" /></div>
                <input className="text-field np role-input-search w-input"
                       value={searchTerm}
                       onChange={(e) => {
                         handleInputChange(e);
                       }}
                       maxLength={256}
                       name="email-6"
                       data-name="Email 6" placeholder="Search for a Role" id="email-6" required="" />
              </div>
              {
                showSkillNotFoundUI && <div className="w-layout-hflex role-not-found-block" style={{ display: 'flex' }}>
                  <p className="paragraph-2">Can&#x27;t find it ? <a href="#" onClick={() => setAddSkillModalFocused(true)}
                                                                     data-w-id="7928874e-8d9b-c71b-91ff-ece7a82efeaa"
                                                                     className="role-select-add">Add it now</a>
                  </p>
                </div>
              }
            </form>
          </div>
          <a href="#" className="link-8 hide">
            See suggested skills
          </a>
          <div className="sug_skills">
            <div className="tx_s">{selectedSkills.length} Selected Skills</div>
            <div className="div-block-83 sp">
              <div className="div-block-17">
                {selectedSkills.map((skill) => (
                  <div
                    key={skill._source.id}
                    className="div-block-16 welcome selected"
                    onClick={() => handleSkillToggle(skill)}
                  >
                    <img
                      src="/images/checked_1checked.png"
                      loading="lazy"
                      alt=""
                      className="image-48"
                    />
                    <div className="text-block-12 welcome selected">
                      {skill._source.name}
                    </div>
                  </div>
                ))}
                {unselectedSearchedSkills.map((skill) => (
                  <div
                    key={skill._source.id}
                    className="div-block-16 welcome"
                    onClick={() => handleSkillToggle(skill)}
                  >
                    <div className="text-block-12 welcome">
                      {skill._source.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-layout-hflex flex-block-38">
              <div className="w-layout-hflex pagination-block">
                {searchedSkills?.num_pages > 1 && (
                  <ReactPaginate
                    onPageChange={paginate}
                    pageCount={searchedSkills?.num_pages}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    containerClassName={
                      'w-layout-hflex pagination-block-container'
                    }
                    pageLinkClassName={
                      'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                    }
                    previousLinkClassName={
                      'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                    }
                    nextLinkClassName={
                      'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                    }
                    activeLinkClassName={
                      'w-layout-hflex pagination-btn table-pagination-btn-active'
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {selectedSkills.length > 0 && (
          <SkillRating
            selectedSkills={selectedSkills}
            roleMin={roleMin}
            handleSkillToggle={handleSkillToggle}
            setSelectedSkills={setSelectedSkills}
          />
        )}
      </div>
      {
        addSkillModalFocused && (
          <div className="add-role-popup" style={{ display: "flex" }}>
            <div className="popup_cont sk lvl">
              <div data-name="followers" className="lvl">
                <div className="div-block-147">
                  <div className="text-block-406">Add your skill</div>
                  <img onClick={()=>handleCloseAddSkill()}  src="/images/close_2close.png" loading="lazy" data-w-id="6100e533-cd8a-0de0-cbf9-a1883c609d74"
                       alt=""
                       className="cancel_edit_profile" />
                </div>
                <div className="div-block-84 level_rate l">
                  <div className="div-block-85 sp">
                    <div className="w-form">
                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                            className="form-20"
                            onSubmit={e=>e.preventDefault()}
                            data-wf-page-id="6596e21ac435cac3c66baaef"
                            data-wf-element-id="9e1b50d1-5b53-232b-e10c-b3b63ebfc8d0"><label
                        htmlFor="name">Title *</label><input className="text-field-22 w-input" maxLength="256" value={fields.title} onChange={handleAddSkillInputChange} name="title"
                                                             data-name="Name" placeholder="e.g Data Analyst" type="text"
                                                             id="name" /></form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popup-f add_content">
                  <div onClick={()=>handleCloseAddSkill()} data-w-id="6100e533-cd8a-0de0-cbf9-a1883c609d9f" className="div-block-113 cancel_edit_prof">
                    <div className="text-block-81 cancel_dc color-white">Cancel</div>
                  </div>
                  <div onClick={!submittingSkill ? ()=>handleAddSkillSubmission() : ()=>{}} data-w-id="6100e533-cd8a-0de0-cbf9-a1883c609da2" className="div-block-113 ls longer">
                    <div className="text-block-81 add_skill_text">Save &amp; Continue</div> {submittingSkill && <span style={{marginLeft:"15px"}}><IBLSpinLoader size={17} color={"#FFF"} /></span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default SkillSelection;
